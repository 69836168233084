import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import createjs from 'easeljs';
import _ from 'underscore';

import ApplicationState from 'app/ApplicationState';

import HiResStage from 'views/canvas/HiResStage';
import HiResStageParameters from 'views/canvas/HiResStageParameters';

import LightSetController from 'controllers/LightSetController';
import LightSetRearHeightHandleController from 'controllers/LightSetRearHeightHandleController';

import template from 'templates/lightset-wizard/lightset-wizard-preview';

export default Marionette.ItemView.extend({

    ui: {
        'projectName': '.lightset-preview__preview-project-name',
        'title': '.lightset-preview__preview-title',
        'canvas': '#lightset-preview',
        'luminaireColor': '.lightset-preview__luminaire-color',
        'bracketColor': '.lightset-preview__bracket-color',
        'poleColor': '.lightset-preview__pole-color',
        'productData': '.lightset-preview__preview-product-data',
        'productText': '.lightset-preview__product-text',
        'customProductTextBox': '.lightset-preview__custom-product-text-box',
        'customProductFormTextarea': '#lightset-preview__custom-product-text-form textarea',
        'changeCustomProductText': '.lightset-preview__custom-product-text-change',
        'saveCustomProductText': '.lightset-wizard__save-custom-text',
        'resetCustomProductText': '.lightset-wizard__reset-custom-text',
        'cancelCustomProductText': '.lightset-wizard__cancel-custom-text'
    },

    template: template,

    events: {
        'dblclick @ui.productData': 'showCustomProductTexTForm',
        'click @ui.saveCustomProductText': 'saveCustomProductText',
        'click @ui.cancelCustomProductText': 'cancelCustomProductText',
        'click @ui.changeCustomProductText': 'showCustomProductTextForm',
        'click @ui.resetCustomProductText': 'resetCustomProductText'
    },

    modelEvents: {
        'change:projectName': 'onChangeProjectName',
        'change:title': 'onChangeTitle',
        'change:luminaireColor': 'onChangePartColor',
        'change:bracketColor': 'onChangePartColor',
        'change:poleColor': 'onChangePartColor'
    },

    cancelCustomProductText() {
        this.ui.customProductTextBox.hide();
        this.ui.productText.show();
        this.ui.changeCustomProductText.show();
    },

    showCustomProductTextForm() {
        this.ui.customProductTextBox.show();
        const currentValue = this.ui.customProductFormTextarea.val();

        if (!currentValue) {
            this.ui.customProductFormTextarea.val(this.getStandardProductText());
        }

        this.ui.productText.hide();
        this.ui.changeCustomProductText.hide();
    },

    getStandardProductText() {
        let standardText = gettext('Luminaire') + ': ' + this.model.get('luminaireModel').name + '\n';
        standardText += this.model.get('luminaireColor').get('name') + '\n';

        if (this.model.get('bracketModel')) {
            standardText += '\n' + gettext('Bracket') + ': ' + this.model.get('bracketModel').name + '\n';
            standardText += this.model.get('bracketColor').get('name') + '\n';
        }

        if (this.model.get('poleModel')) {
            standardText += '\n' + gettext('Pole') + ': ' + this.model.get('poleModel').name + '\n';
            standardText += this.model.get('poleColor').get('name') + '\n';
        }

        return standardText;
    },

    serializeData(){
        let data = Marionette.ItemView.prototype.serializeData.call(this);

        if (data.customProductText) {
            data.customProductTextInput = data.customProductText;
            data.customProductText = this.newLinesToBr(_.escape(data.customProductText));
        }

        return data;
    },

    newLinesToBr(string) {
        return (string + '').replace(/(\r\n|\n\r|\r|\n)/g, '<br>' + '$1');
    },

    saveCustomProductText(event) {
        event.preventDefault();
        const customText = this.ui.customProductFormTextarea.val();

        if (customText) {
            const formattedText = this.newLinesToBr(_.escape(customText));
            this.ui.productText.html(formattedText);
            this.model.set('customProductText', customText);
        }

        this.ui.customProductTextBox.hide();
        this.ui.productText.show();
        this.ui.changeCustomProductText.show();
    },

    resetCustomProductText(event) {
        event.preventDefault();
        this.ui.customProductFormTextarea.val('');
        this.model.set('customProductText', '');
        this.render();
    },

    onRender() {
        const canvas = this.ui.canvas[0];
        const stageParams = new HiResStageParameters(
            canvas,
            (ApplicationState.get('cordova')) ? 490 : 600,
            600,
            ApplicationState.get('canvasDpiMultiplier')
        );

        this.stage = new HiResStage(stageParams);

        this.lightSetController = new LightSetController();
        this.lightSetController.on(LightSetController.UPDATED, this.onLightSetUpdated, this);
        this.lightSetController.model = this.model;
        this.lightSetController.renderingIndex = 0;
        this.lightSetController.resultWidth = canvas.width;
        this.lightSetController.resultHeight = canvas.height;
        this.lightSetController.graphicsScale = stageParams.dpiMultiplier;
        this.lightSetController.render();

        let bg = new createjs.Shape();

        bg.graphics.drawRect(0, 0, 3000, 3000);
        this.stage.addChild(bg);
        this.stage.enableMouseOver(10);

        let output = this.lightSetController.output;

        this.stage.addChild(output);

        if (this.model.get('rearBracketModel')) {
            let range = this.lightSetController.rearBracketYRange;

            if (range[1] - range[0] > 500) {
                this.renderHeightControls();
            }
        }
    },

    renderHeightControls() {
        let callback = this._onHeightChange.bind(this);
        let current = this.lightSetController.rearBracketHeight;
        let min = this.lightSetController.rearBracketYRange[0];
        let max = this.lightSetController.rearBracketYRange[1];
        let scale = this.lightSetController.scale;

        let handle = new LightSetRearHeightHandleController(this.stage, callback, current, min, max, scale);
    },

    _onHeightChange(height) {
        this.lightSetController.rearBracketHeight = height;
    },

    onLightSetUpdated(evt) {
        this.stage.update();
    },

    onChangeProjectName(model, newProjectName) {
        this.ui.projectName.text(newProjectName);
    },

    onChangeTitle(model, newTitle) {
        this.ui.title.text(newTitle);
    },

    onChangePartColor(model, newColor) {
        for (var prop in model.changed) {
            this.ui[prop].text(newColor.get('name'));
        }
    },

    serializeModel: function() {
        let data = Marionette.ItemView.prototype.serializeModel.apply(this, arguments);

        data.luminaireColor = data.luminaireColor.toJSON();
        data.bracketColor = data.bracketColor.toJSON();
        data.poleColor = data.poleColor.toJSON();

        return data;
    }
});
