
// import Marionette from 'backbone.marionette';
// import Backbone from 'backbone';
import AbstractIntroLayoutView from 'views/intro/AbstractIntroLayoutView';

import Analytics from 'utils/Analytics';


export default AbstractIntroLayoutView.extend({
    
    onStartClick() {
        Analytics.track('start_lightset_creator:button');
    }

});
