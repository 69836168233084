import async from 'async';
import AbstractPDF from 'controllers/pdf/AbstractPDF';
import LightSetPage from 'controllers/pdf/LightSetPage';

export default
    class PDFRenderer
    extends AbstractPDF
{
    get orientation() {
        return 'portrait';
    }
    
    get steps() {
        return [this._addLightSetPage.bind(this)];
    }
    
    _addLightSetPage(callback) {
        this.model.set({
            indicatorTotal: 1,
            indicatorCurrent: 0
        });

        const lightSetModel = this.model.get('lightSetModel');
        new LightSetPage(this.pdf, this.model, lightSetModel, true, function(...params) {
            this.model.set('indicatorCurrent', 1);
            callback.apply(this, params);
        }.bind(this));
    }
}