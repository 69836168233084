// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.customProductTextInput || (depth0 != null ? depth0.customProductTextInput : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"customProductTextInput","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                "
    + ((stack1 = ((helper = (helper = helpers.customProductText || (depth0 != null ? depth0.customProductText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"customProductText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression, alias3=container.lambda;

  return "\n                <p>\n                    <strong>"
    + alias2((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Luminaire",{"name":"trans","hash":{},"data":data}))
    + ":</strong> "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.luminaireModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n                    <span class=\"lightset-preview__color-label lightset-preview__luminaire-color\">"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.luminaireColor : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n                </p>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.bracketModel : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.poleModel : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                    <p>\n                        <strong>"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Bracket",{"name":"trans","hash":{},"data":data}))
    + ":</strong>  "
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.bracketModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n                ,\n                        <span class=\"lightset-preview__color-label lightset-preview__bracket-color\">"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.bracketColor : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n                    </p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                    <p>\n                        <strong>"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Pole",{"name":"trans","hash":{},"data":data}))
    + ":</strong> "
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.poleModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n                        <span class=\"lightset-preview__color-label lightset-preview__pole-color\">"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.poleColor : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n                    </p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"lightset-preview__preview-container\">\n	<div class=\"lightset-preview__preview-header\">\n		<h3 class=\"lightset-preview__preview-title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n		<h4 class=\"lightset-preview__preview-project-name\">"
    + alias4(((helper = (helper = helpers.projectName || (depth0 != null ? depth0.projectName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"projectName","hash":{},"data":data}) : helper)))
    + "</h4>\n	</div>\n	<canvas id=\"lightset-preview\" width=\"620\" height=\"620\"></canvas>\n	<div class=\"lightset-preview__preview-product-data\">\n\n        <div class=\"lightset-preview__custom-product-text-box\">\n            <form id=\"lightset-preview__custom-product-text-form\">\n                <textarea maxlength=\"400\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.customProductText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</textarea>\n                <button class=\"lightset-wizard__save-custom-text button button--primary\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Save",{"name":"trans","hash":{},"data":data}))
    + "</button>\n                <button class=\"lightset-wizard__reset-custom-text button button--primary\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reset",{"name":"trans","hash":{},"data":data}))
    + "</button>\n                <span class=\"lightset-wizard__cancel-custom-text\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            </form>\n        </div>\n\n        <div class=\"lightset-preview__product-text\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.customProductText : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n        <span class=\"lightset-preview__custom-product-text-change\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Change",{"name":"trans","hash":{},"data":data}))
    + "</span>\n	</div>\n	<div class=\"lightset-preview__preview-background\"></div>\n	<div class=\"lightset-preview__preview-footer\">\n		<div class=\"lightset-preview__preview-disclaimer\">\n			<h4 class=\"lightset-preview__preview-disclaimer-title\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"© 2017 Koninklijke Philips N.V. All rights reserved.",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n			<p class=\"lightset-preview__preview-disclaimer-text\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Philips reserves the right to make changes in specifications and/or to discontinue any product at any time without notice or obligation and will not be liable for any consequences resulting from the use of this publication.",{"name":"trans","hash":{},"data":data}))
    + " <a href=\"http://www.lighting.philips.com\">www.lighting.philips.com</a></p>\n		</div>\n		<div class=\"lightset-preview__preview-logo\"><img src=\"img/pdf/logo.png\"></div>\n	</div>\n</div>\n\n\n";
},"useData":true});
