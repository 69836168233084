// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "			<fieldset class=\"lightset-wizard__change-color-fieldset\">\n				<label>"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Bracket",{"name":"trans","hash":{},"data":data}))
    + ": "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.bracketModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "</label>\n				<div id=\"lightset-wizard-select-bracket-color-region\"></div>\n			</fieldset>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "			<fieldset class=\"lightset-wizard__change-color-fieldset\">\n				<label>"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Pole",{"name":"trans","hash":{},"data":data}))
    + ": "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.poleModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "</label>\n				<div id=\"lightset-wizard-select-pole-color-region\"></div>\n			</fieldset>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"lightset-wizard__result-wrapper\">\n	<div id=\"lightset-wizard-preview-region\" class=\"lightset-wizard__preview-section\"></div>\n	<div class=\"lightset-wizard__sidebar lightset-wizard__sidebar--wide\">\n		<h2>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Products",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n		<div class=\"checkbox\">\n			<input type=\"checkbox\" class=\"checkbox__label\" id=\"lightset-wizard-color-individually\" checked=\"checked\">\n			<label class=\"checkbox__label\" for=\"lightset-wizard-color-individually\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Color parts individually",{"name":"trans","hash":{},"data":data}))
    + "</label>\n		</div>\n		<div id=\"lightset-wizard-global-color-section\">\n			<fieldset class=\"lightset-wizard__change-color-fieldset\">\n				<label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Entire lightset",{"name":"trans","hash":{},"data":data}))
    + "</label>\n				<div id=\"lightset-wizard-select-global-color-region\"></div>\n			</fieldset>\n		</div>\n		<div id=\"lightset-wizard-individual-colors-section\">\n			<fieldset class=\"lightset-wizard__change-color-fieldset\">\n				<label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Luminaire",{"name":"trans","hash":{},"data":data}))
    + ": "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.luminaireModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "</label>\n				<div id=\"lightset-wizard-select-luminaire-color-region\"></div>\n			</fieldset>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.bracketModel : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.poleModel : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"lightset-wizard__select-background-section\">\n			<h2>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Select background",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n			<div class=\"lightset-wizard__background-options\" id=\"lightset-wizard-background-options-wall\">\n				<a href=\"javascript:void(0)\" class=\"lightset-wizard__background-option\" data-background=\"wallmounted\"><img src=\"img/lightset-wizard/backgrounds/thumbnails/wall.jpg\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wall",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wall",{"name":"trans","hash":{},"data":data}))
    + "</a>\n				<a href=\"javascript:void(0)\" class=\"lightset-wizard__background-option\" data-background=\"\"><img src=\"data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"No Background",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"No Background",{"name":"trans","hash":{},"data":data}))
    + "</a>\n			</div>\n			<div class=\"lightset-wizard__background-options\" id=\"lightset-wizard-background-options-regular\">\n				<a href=\"javascript:void(0)\" class=\"lightset-wizard__background-option\" data-background=\"default\"><img src=\"img/lightset-wizard/backgrounds/thumbnails/park.jpg\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Park",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Park",{"name":"trans","hash":{},"data":data}))
    + "</a>\n				<a href=\"javascript:void(0)\" class=\"lightset-wizard__background-option\" data-background=\"road\"><img src=\"img/lightset-wizard/backgrounds/thumbnails/street_car.jpg\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Street with car",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Street with car",{"name":"trans","hash":{},"data":data}))
    + "</a>\n				<a href=\"javascript:void(0)\" class=\"lightset-wizard__background-option\" data-background=\"building\"><img src=\"img/lightset-wizard/backgrounds/thumbnails/street_building.jpg\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Street with building",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Street with building",{"name":"trans","hash":{},"data":data}))
    + "</a>\n				<a href=\"javascript:void(0)\" class=\"lightset-wizard__background-option\" data-background=\"house\"><img src=\"img/lightset-wizard/backgrounds/thumbnails/residential_street.jpg\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Residential street",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Residential street",{"name":"trans","hash":{},"data":data}))
    + "</a>\n				<a href=\"javascript:void(0)\" class=\"lightset-wizard__background-option\" data-background=\"\"><img src=\"img/lightset-wizard/backgrounds/thumbnails/nobackground.gif\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"No Background",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"No Background",{"name":"trans","hash":{},"data":data}))
    + "</a>\n			</div>\n		</div>\n		<div class=\"lightset-wizard__project-info-section\">\n			<h2>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Project info",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n			<fieldset class=\"lightset-wizard__text-fieldset\">				\n				<label for=\"lightset-wizard-title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Title",{"name":"trans","hash":{},"data":data}))
    + ":</label><input id=\"lightset-wizard-title\" name=\"lightset-wizard-title\" class=\"lightset-wizard__title-input\" value=\""
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n			</fieldset>\n			<fieldset class=\"lightset-wizard__text-fieldset\">\n				<label for=\"lightset-wizard-project-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Project name",{"name":"trans","hash":{},"data":data}))
    + ":</label><input id=\"lightset-wizard-project-name\" name=\"lightset-wizard-project-name\" class=\"lightset-wizard__project-name-input\" value=\""
    + alias3(((helper = (helper = helpers.projectName || (depth0 != null ? depth0.projectName : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"projectName","hash":{},"data":data}) : helper)))
    + "\">				\n			</fieldset>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
