import Backbone from 'backbone';
import AbstractLightsetWizardLayoutView from 'views/lightset-wizard/AbstractLightsetWizardLayoutView';
import ApplicationState from 'app/ApplicationState';
import $ from 'jquery';
import Analytics from 'utils/Analytics';

export default
    class LightsetWizardLayoutView
    extends AbstractLightsetWizardLayoutView
{
    get stepFunctions() {
        return [
            'viewIntro',
            'showTypeAndHeight',
            'showLuminaires',
            'showBrackets',
            'showPoles',
            'showResult'
        ];
    }

    onNext() {
        if (this.nextStep > this.model.get('steps')) {
            this.renderPdf();
        }

        super.onNext();
    }

    viewIntro() {
        Backbone.history.navigate('', {trigger: true});
    }

    renderPdf() {
        Analytics.track('render_pdf:button');
        ApplicationState.set('lightSetModel', this.lightSetModel);
        ApplicationState.set('lightSetWizardModel', this.model);
        Backbone.history.navigate('/render', {trigger:true});
    }

    onStepChange(model, value) {
        const stepFunctions = this.stepFunctions;
        const isShowingResult = value === stepFunctions.indexOf('showResult');

        // Hack hack, optimize if you got more time than me ;)
        $('.creator').toggleClass('expanded', isShowingResult);

        super.onStepChange(model, value);
    }

    _processResultLabel() {
        return gettext('Render PDF');
    }

    _useBackgroundInResultView() {
        return true;
    }
};
