import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import ApplicationState from 'app/ApplicationState';

import IntroLayoutView from 'views/intro/IntroLayoutView';
import CreatorLayoutView from 'views/creator/CreatorLayoutView';
import RenderLayoutView from 'views/render/RenderLayoutView';

export default
    class MainController
    extends Marionette.Object
{
    constructor(mainRegion, modalOverlayRegion) {
        super();

        ApplicationState.set('target', 'creator');

        this.mainRegion = mainRegion;
        this.modalOverlayRegion = modalOverlayRegion;
    }

    showIntro() {
        const model = new Backbone.Model({
            applicationModel: ApplicationState.get('applicationData'),
            title: gettext('Create your own 2D complete light point solution'),
            intro: gettext('The Lightset Creator enables you to create your own light point solution from scratch. In just a few steps you can select point type and height, luminaire, bracket and pole. It is also possible to change the color individually for each element.'),
            language: {
                label: gettext('Language')
            },
            btn: {
                label: gettext('Start LightSet Creator'),
                href: '#creator'
            }
        });

        this._showView(IntroLayoutView, {
            model: model
        });
    }

    showCreator() {
        this._showView(CreatorLayoutView);
    }

    showRender() {
        const lightSetWizardModel = ApplicationState.get('lightSetWizardModel');
        const lightSetModel = ApplicationState.get('lightSetModel');
        if (lightSetWizardModel != null && lightSetModel != null) {
            const model = new Backbone.Model({
                lightSetModel: lightSetModel,
                disclaimerText: gettext('© 2017 Koninklijke Philips N.V. All rights reserved. Philips reserves the right to make changes in specifications and/or to discontinue any product at any time without notice or obligation and will not be liable for any consequences resulting from the use of this publication. www.lighting.philips.com'),
                indicatorTotal: 1,
                indicatorCurrent: 0
            });
            this._showView(RenderLayoutView, {model: model});
        } else {
            Backbone.history.navigate(ApplicationState.get('target'), {trigger: true, replace: true});
        }
    }

    _showView(LayoutManagerClass, options = null) {
        const manager = new LayoutManagerClass(options);
        if (LayoutManagerClass === RenderLayoutView) {
            this.modalOverlayRegion.show(manager);
            this.modalOverlayRegion.$el.show();
        } else {
            this.modalOverlayRegion.empty();
            this.modalOverlayRegion.$el.hide();

            const returningToCreator = (LayoutManagerClass === CreatorLayoutView) && (this.mainRegion.currentView instanceof CreatorLayoutView);
            if (!returningToCreator) {
                // Only if the creator is not already shown, to prevent loss of creator expansion
                this.mainRegion.show(manager);
            }
        }
    }
}
