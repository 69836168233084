// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<h1 class=\"lightset-wizard__title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h1>\n<button class=\"lightset-wizard__reset text-button\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reset",{"name":"trans","hash":{},"data":data}))
    + " <span class=\"icon-reset\"></span></button>		\n";
},"useData":true});
