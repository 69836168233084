import Backbone from 'backbone';
import _ from 'underscore';
import ApplicationState from 'app/ApplicationState';

export default class LightsetWizardDataUtil {

    static getHeights() {
        return ApplicationState.get('heightsData');
    }

    static getLightPointTypes() {
        return ApplicationState.get('lightPointTypesData');
    }

    static get DOUBLE_MIN_HEIGHT() {
        return 5000;
    }

    static get REAR_MIN_HEIGHT() {
        return 6000;
    }

    static _sortModelsByName(models) {
        return models.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
                return -1;
            } else if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    static _getPreviewImage(models, selector){
        const firstFilteredModel = _.find(models, (model) => {
            return selector(model);
        });

        if (firstFilteredModel && firstFilteredModel.main) {
            return firstFilteredModel.main;
        } else {
            // When no models are left after filtering return the preview for the first model in the complete set
            return (models[0] && models[0].main) ? models[0].main : '';
        }
    }

    static _clone(object) {
        // Clone an object to make sure changes to the properties don't end up in
        // for example ApplicationState
        return JSON.parse(JSON.stringify(object));
    }

    static getLuminaireFamiliesToDisplay(lightPointType, height){
        let luminaireFamilies = this.getLuminaireFamilies(lightPointType);
        const selector = this.getLuminaireModelSelector(lightPointType, height);
        const filteredFamilies = luminaireFamilies.map((family) => {
            let luminaireFamily = this._clone(family);

            let models = luminaireFamily.models;
            // we add the first model of a group here to make sure the images we choose the family preview image from
            // is always among the displayed images when showing luminaireModels
            let groupModels = this._getFirstLuminaireModelsFromGroups(luminaireFamily.model_groups, selector);
            let previewModels = models.concat(groupModels);

            luminaireFamily.main = this._getPreviewImage(previewModels, selector);

            return luminaireFamily;
        });

        return this._sortModelsByName(filteredFamilies);
    }

    static getLuminaireFamilies(lightPointType) {

        let filter = {};
        if (lightPointType != 'luminaire-only') {
            // Luminaire shows all luminaires so
            filter = {[lightPointType]: true};
        }

        return _.where(ApplicationState.get('luminairesData'), filter);
    }

    static _getFirstLuminaireModelsFromGroups(modelGroups, selector) {
        let modelGroupFirstEntries = [];

        _.each(modelGroups, (modelGroup, key, list) => {
            if (modelGroup.models.length > 0) {
                const firstFilteredModel = _.find(modelGroup.models, (model) => {
                    return selector(model);
                });

                if (firstFilteredModel) {
                    let clonedModelInGroup = this._clone(firstFilteredModel);

                    clonedModelInGroup.name = modelGroup.name;

                    modelGroupFirstEntries.push(
                        clonedModelInGroup
                    );
                }
            }
        });

        return modelGroupFirstEntries;
    }

    static _getModelsFromModelGroups(family){
        let models = [];
        if (family.get('model_groups')) {
            _.each(family.get('model_groups'), (model_group) => {
                models = models.concat(model_group.models);
            });
        }
        return models;
    }

    static getLuminaireModels(luminaireFamily, lightPointType, height) {
        const selector = this.getLuminaireModelSelector(lightPointType, height);

        let modelGroupLuminaires = [];

        if (
            lightPointType === 'luminaire-only' ||
            lightPointType === 'top' ||
            lightPointType === 'spiral' ||
            lightPointType === 'column' ||
            ApplicationState.get('target') === 'exporter') {
            modelGroupLuminaires = this._getModelsFromModelGroups(luminaireFamily);
        } else {
            modelGroupLuminaires =
                this._getFirstLuminaireModelsFromGroups(luminaireFamily.get('model_groups'), selector);

        }

        const models = modelGroupLuminaires.concat(luminaireFamily.get('models'));

        const filteredModels = _.filter(
            models,
            (model) => {
                return selector(model);
            }
        );

        return this._sortModelsByName(filteredModels);
    }

    static getLuminaireModelSelector(lightPointType, height){
        // Slightly expensive but effective means of checking wether or not to show the luminaire.
        // Filter out any models that don't have brackets while they should (being non-post-top) and/or
        // don't have poles while they should (post-top).
        // top, single, double, wall, rear, special
        return function(model) {
            switch (lightPointType){

                case 'column':
                case 'luminaire-only': {
                    // columns and luminaire-only have no requirements since they consist only of a luminaire
                    return true;
                }

                case 'top': {
                    if (model['top'] === true && model.suspended != true) {
                        // Post-top configurations need at least one Pole option
                        return LightsetWizardDataUtil.getPolesFromLuminaire(model, lightPointType, height).length > 0;
                    }

                    return false;
                }

                case 'rear': {
                    return model[lightPointType] === true && (model.size === undefined || model.size === 'gm');
                }

                case 'spiral': {
                    return model[lightPointType] === true
                }

                case 'wall': {
                    // Wall-mounted luminaires always need a bracket
                    return LightsetWizardDataUtil.getBracketFamilies(model, lightPointType, height).length > 0;
                }

                case 'single':
                case 'double': {
                    // Single and double need at least one bracket or pole to attach to...
                    return LightsetWizardDataUtil.getPolesFromLuminaire(model, lightPointType, height).length > 0
                        || LightsetWizardDataUtil.getBracketFamilies(model, lightPointType, height).length > 0;
                }

            }
        };
    }

    static getLuminaireModelGroups(lightPointType) {
        const luminaireFamilies = this.getLuminaireFamilies(lightPointType);

        let modelGroups = [];

        _.each(luminaireFamilies, (family, index) => {
            if (family.model_groups.length > 0) {
                modelGroups = modelGroups.concat(family.model_groups);
            }
        });

        return modelGroups;
    }

    static getLuminaireModelGroupById(lightPointType, id) {
        const modelGroups = this.getLuminaireModelGroups(lightPointType);

        const result = _.find(modelGroups, (modelGroup) => {
            return modelGroup.id == id;
        });

        return result;
    }

    static getLuminaireModelByBracketId(luminaireModels, bracketId) {
        let luminaireModel = _.find(luminaireModels, (luminaireModel) => {
            return _.findWhere(luminaireModel.brackets, {id: bracketId});
        });

        return luminaireModel;
    }

    static getBracketFamiliesToDisplay(luminaireModel, lightPointType, height) {
        // We collect brackets belonging either to the selected luminaireModel or if the luminaireModel is part
        // of a modelgroup the brackets belonging to all the luminaireModels in that group
        const selector = this._getBracketSelector(lightPointType, luminaireModel, height);
        let brackets = this.getBracketFamilies(luminaireModel, lightPointType, height);

        const bracketFamilies = brackets.map((family) => {
            let bracketFamily = this._clone(family);

            bracketFamily.main = this._getPreviewImage(bracketFamily.models, selector);
            return bracketFamily;
        });

        return this._sortModelsByName(bracketFamilies);
    }

    static _getAllModelsInModelsGroup(luminaireModel, lightPointType){
        let luminaireModels = [];

        // Prototype method is called for safety
        const isLuminaireModelInGroup = Object.prototype.hasOwnProperty.call(
            luminaireModel, 'group_id'
        );

        // If the luminaireModel is part of a model group return all luminairemodels in the group
        if (isLuminaireModelInGroup) {
            const modelGroup = LightsetWizardDataUtil.getLuminaireModelGroupById(
                lightPointType, luminaireModel.group_id
            );

            luminaireModels = modelGroup.models;
        } else {
            luminaireModels.push(luminaireModel);
        }

        return luminaireModels;
    }

    static getBracketFamilies(luminaireModel, lightPointType, height) {
        const luminaireModels = this._getAllModelsInModelsGroup(luminaireModel, lightPointType);
        let brackets = [];

        _.each(luminaireModels, (luminaireModel) => {
            _.each(luminaireModel.brackets, (bracketEntry) => {
                const bracketFamily = _.findWhere(ApplicationState.get('bracketsData'), {'id': bracketEntry.id});

                if (
                    bracketFamily[lightPointType] === true &&
                    this.getBracketModels(bracketFamily, lightPointType, luminaireModel, height).length > 0
                ) {
                    brackets.push(bracketFamily);
                }
            });
        });

        return brackets;
    }

    static _getBracketSelector(lightPointType, luminaireModel, height) {
        const luminaireModels = this._getAllModelsInModelsGroup(luminaireModel, lightPointType);

        return function(model){
            if (model.size === 'pm' && height.min === 8000) {
                return false;
            }

            if (model.poles.length === 0 && model.type != 'wall') return false;

            const fitsOrientation = _.findWhere(luminaireModels, {'suspended': model.suspended});

            if (lightPointType === 'double') {
                return fitsOrientation &&
                    model.type === lightPointType &&
                    (model.size === undefined || model.size === 'gm');
            } else if (lightPointType === 'rear') {
                return fitsOrientation && model.type === 'single' && (model.size === undefined || model.size === 'gm');
            } else {
                return fitsOrientation && model.type === lightPointType;
            }
        };
    }

    static updateLuminaireModelByBracket(lightSetModel, bracketFamily, lightPointType) {
        // A Luminaire model in a group is initially selected
        // by referring to the first model in a group
        // it is only when the bracket has been selected
        // that we are able to retroactively select the right
        // luminaire in a group

        // Prototype method is called for safety
        const isLuminaireModelInGroup = Object.prototype.hasOwnProperty.call(
            lightSetModel.get('luminaireModel'), 'group_id'
        );

        if (isLuminaireModelInGroup) {
            const luminaireModel = lightSetModel.get('luminaireModel');
            const modelGroup = this.getLuminaireModelGroupById(
                lightPointType,
                luminaireModel.group_id
            );
            const correctModel = this.getLuminaireModelByBracketId(
                modelGroup.models,
                bracketFamily.get('id')
            );

            lightSetModel.set({'luminaireModel': correctModel}, {silent: true});
        }
    }

    static getBracketModels(bracketFamily, lightPointType, luminaireModel, height) {
        const selector = this._getBracketSelector(lightPointType, luminaireModel, height);

        let bracketModels = [];
        if (bracketFamily.models) {
            bracketModels = bracketFamily.models
        } else if(bracketFamily.get) {
            bracketModels = bracketFamily.get('models');
        }

        let models = _.filter(bracketModels, function(model){
            return selector(model);
        });

        return this._sortModelsByName(models);
    }

    static getRearModel(models, frontModel) {
        const rearModel = frontModel.get('size') === undefined ?
                frontModel :
                new Backbone.Model(_.findWhere(models, {size: 'pm'}));

        return rearModel;
    }

    static getPolesFromLuminaire(luminaireModel, lightPointType) {
        const poleFamilies = [];

        // Luminaires attach to post-top, single and double poles, so filter those using the chosen light point type
        luminaireModel && _.each(luminaireModel.poles, (poleFamilyReference) => {
            const poleFamily = _.findWhere(ApplicationState.get('polesData'), {'id': poleFamilyReference.id});
            let poleIsCompatible = false;

            if (luminaireModel.poleSideMounting) {
                poleIsCompatible = poleFamily.sideMountingCompatible === true;
            } else {
                poleIsCompatible = poleFamily[lightPointType] === true;
            }

            if (poleIsCompatible) {
                poleFamilies.push(poleFamily);
            }
        });

        return poleFamilies;
    }

    static getPolesFromBracket(bracketModel) {
        const poleFamilies = [];
        // Brackets attach only to post-top poles, so filter those from the bracket-poles
        bracketModel && _.each(bracketModel.poles, (poleFamilyReference) => {
            const poleFamily = _.findWhere(ApplicationState.get('polesData'), {'id': poleFamilyReference.id});

            if (poleFamily['top'] === true) {
                poleFamilies.push(poleFamily);
            }
        });

        return poleFamilies;
    }
    static getPoleFamiliesToDisplay(luminaireModel, bracketModel, lightPointType, height){
        const poleFamilies = this.getPoleFamilies(luminaireModel, bracketModel, lightPointType, height);
        const selector = this._getPoleModelSelector(lightPointType, height, luminaireModel, bracketModel);

        let poleFamiliesToDisplay = poleFamilies.map((family) => {
            let poleFamily = this._clone(family);
            poleFamily.main = this._getPreviewImage(poleFamily.models, selector);
            return poleFamily;
        });

        return this._sortModelsByName(poleFamiliesToDisplay);
    }

    static _getPoleModelSelector(lightPointType, height, luminaireModel, bracketModel=null){
        return function(model){
            const minHeight = Math.max(
                height.min, luminaireModel.hmin, bracketModel && bracketModel.hmin ? bracketModel.hmin : 0
            );

            const maxHeight = Math.min(
                height.max, luminaireModel.hmax, bracketModel && bracketModel.hmax ? bracketModel.hmax : 99999
            );

            if (model.height < minHeight || model.height > maxHeight) {
                return false;
            }

            switch (lightPointType) {
                case 'rear': {
                    return model.height >= LightsetWizardDataUtil.DOUBLE_MIN_HEIGHT;
                    break;
                }

                default: {
                    if (bracketModel !== null) {
                        // If the model has a bracket, it needs a post-top pole, so we filter out the rest
                        return model.type === 'top';
                    } else {
                        // Conversely, if the model does not have a bracket, it needs either a Single or Double pole
                        // Or a pole that is side-mounting compatible
                        return model.type === lightPointType ||
                            (luminaireModel.poleSideMounting && model.sideMountingCompatible);
                    }
                }
            }
        };
    }

    static getPoleFamilies(luminaireModel, bracketModel, lightPointType) {
        // Poles can be attached to the bracket and/or to the luminaire directly, so consider both options.
        // If a bracket has been chosen, return only the poles attached to that bracket.
        // If no bracket has been chosen, return only the poles attached to the luminaire.

        return bracketModel ? LightsetWizardDataUtil.getPolesFromBracket(bracketModel)
                            : LightsetWizardDataUtil.getPolesFromLuminaire(
                                luminaireModel, lightPointType);
    }

    static getPoleModels(poleFamily, lightPointType, height, luminaireModel, bracketModel=null) {
        const selector = this._getPoleModelSelector(lightPointType, height, luminaireModel, bracketModel);
        let models = _.filter(poleFamily.get('models'), function(model){
            return selector(model);
        });

        return this._sortModelsByName(models);
    }

    static getColors() {
        return ApplicationState.get('ralData');
    }

    static getDefaultColor() {
        return new Backbone.Model(this.getColors()[0]);
    }

    static getBackgrounds() {
        return ApplicationState.get('backgroundData');
    }

    static getBackground(backgroundId) {
        const backgrounds = this.getBackgrounds();
        const background = _.findWhere(backgrounds, {'id': backgroundId});

        return background;
    }

    static getDefaultBackground() {
        return this.getBackgrounds()[0].id;
    }

    // todo: refactor this so that the info is included in the luminaire's brackets (property: 'adapter')
    static getAdapter(lightSetModel) {
        const bracketInfo = lightSetModel.get('bracket');

        if (bracketInfo != null) {
            const luminaireModel = lightSetModel.get('luminaireModel');
            const bracket = _.findWhere(luminaireModel.brackets, {'id': bracketInfo.id});

            if (bracketInfo.adapter) {
                const adapter = _.findWhere(ApplicationState.get('bracketsData'), {'id': bracketInfo.adapter});
                const size = luminaireModel.size;
                const adapterModel = size != null ? _.findWhere(adapter.models, {'size': size}) : adapter.models[0];

                return adapterModel;
            }
        }

        return null;
    }
};
