// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<div class=\"intro\">\n    <div class=\"container container--inner\">\n        <div class=\"intro__wrapper\">\n            <img src=\"img/intro/intro-lightset.jpg\" alt=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"intro__text\">\n                <h1 class=\"intro__title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h1>\n                <p class=\"intro__description\">"
    + alias4(((helper = (helper = helpers.intro || (depth0 != null ? depth0.intro : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"intro","hash":{},"data":data}) : helper)))
    + "</p>\n                <div class=\"language\">\n                    <label class=\"language__label\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.language : depth0)) != null ? stack1.label : stack1), depth0))
    + "</label>\n                    <div id=\"language-region\" class=\"language__select\"></div>\n                </div>\n                <a id=\"start-tool\" class=\"button button--primary\" href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.btn : depth0)) != null ? stack1.href : stack1), depth0))
    + "\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.btn : depth0)) != null ? stack1.label : stack1), depth0))
    + "</a>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
