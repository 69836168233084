import AbstractLayoutView from 'views/abstract/AbstractLayoutView';

import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';
import LightsetWizardLayoutView from 'views/lightset-wizard/LightsetWizardLayoutView';
import LightSetWizardModel from 'models/lightset-wizard/Wizard';
import LightSetModel from 'models/lightset-wizard/LightSet';

import template from 'templates/creator/creator';

export default
    class CreatorLayoutView
    extends AbstractLayoutView {

    get template() {
        return template;
    }

    onBeforeShow() {
        this.showChildView('mainRegion', new LightsetWizardLayoutView({
            lightSetModel: new LightSetModel({
                background: LightsetWizardDataUtil.getDefaultBackground()
            }),
            model: new LightSetWizardModel()
        }));
    }

    _defineRegions() {
        return {
            mainRegion: '#creator-main-region'
        };
    }
}
