import AbstractLayoutView from 'views/abstract/AbstractLayoutView';

import RenderingView from 'views/render/RenderingView';
import FinishedView from 'views/render/FinishedView';

import template from 'templates/render/layout';

export default
    class RenderLayoutView 
    extends AbstractLayoutView 
{
    get template() {
        return template;
    }

    get className() {
        return 'render';
    }

    get childEvents() {
        return {
            'render:completed': this._onRenderCompleted
        };
    }

    _onRenderCompleted() {
        this.showChildView('mainRegion', new FinishedView({model: this.model}));
    }

    _defineRegions() {
        return {
            'mainRegion': '#render-main-region'
        };
    }

    onRender() {
        this.showChildView('mainRegion', new RenderingView({model: this.model}));
    }
}
