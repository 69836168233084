import Marionette from 'backbone.marionette';

export default 
    class MainRouter 
    extends Marionette.AppRouter {        
        
    get appRoutes() {
        return {
            '': 'showIntro',
            'creator': 'showCreator',
            'render': 'showRender'
        }
    }
}
